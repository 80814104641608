import { React, useState, useRef } from 'react'
import './App.css';

import { Container, Row, Col, Button } from 'react-bootstrap';

import { Parallax, ParallaxLayer } from '@react-spring/parallax'

import styles from './styles.module.css'

export default function App() {
  const alignCenter = { display: 'flex', alignItems: 'center' }
  return (
    <div>
      <div className={styles.background} />
      <Parallax pages={6} style={{ top: '0', left: '0' }}>

        <ParallaxLayer offset={0} speed={0.5} style={{ ...alignCenter, justifyContent: 'center' }}>
          <p className={styles.scrollText}>Practice Compete Qode.</p>
        </ParallaxLayer>

        <ParallaxLayer sticky={{ start: 1, end: 3 }} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
          <div className={`${styles.card} ${styles.sticky}`}>
            <p style={{fontSize: '6rem'}}>Qode</p>
            <p style={{fontSize: '3rem'}}>by MSC KIIT</p>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0.1} style={{ ...alignCenter, justifyContent: 'flex-end' }}>
          <div className={`${styles.card} ${styles.parallax} ${styles.purple}`}>
            <p style={{fontSize: '5rem'}}>Code</p>
            <p>
              <ul>
                <li>Full Fledged IDE with C++, Java, Python and more support!</li>
                <li>VS-Code key binds supported out of the box</li>
              </ul>
            </p>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={2} speed={0.1} style={{ ...alignCenter, justifyContent: 'flex-end' }}>
          <div className={`${styles.card} ${styles.parallax} ${styles.blue}`}>
            <p style={{fontSize: '5rem'}}>Practice</p>
            <p>
              <ul>
                <li>Over 1000+ questions of Data Structures and Algorithms</li>
                <li>From LeetCode, GeeksForGeeks, CodeChef, CodeForces, SPOJ, AtCoder and more!</li>
                <li>Curated by industry experts - and trusted by hundreds of thousands.</li>
              </ul>
            </p>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={3} speed={0.1} style={{ ...alignCenter, justifyContent: 'flex-end' }}>
          <div className={`${styles.card} ${styles.parallax} ${styles.orange}`}>
            <p style={{fontSize: '5rem'}}>Compete</p>
            <p>Compete with hundreds of other coders at our home-brewed hackathons.</p>
          </div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={4}
          speed={0.5}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <p className={styles.scrollText}>Why Qode?</p>

          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div>
                  <p style={{fontSize: '5rem'}}>Practice</p>
                  <p>
                    <ul>
                      <li>Over 1000+ questions of Data Structures and Algorithms</li>
                      <li>From LeetCode, GeeksForGeeks, CodeChef, CodeForces, SPOJ, AtCoder and more!</li>
                      <li>Curated by industry experts - and trusted by hundreds of thousands.</li>
                    </ul>
                  </p>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <img className={styles.landingImg} src="https://user-images.githubusercontent.com/60937304/137291442-d8525b50-40c7-42a7-a1ac-8765be955214.png" />
              </Col>
            </Row>

            <Row>
            <Col xs={12} md={6}>
                <img className={styles.landingImg} src="https://user-images.githubusercontent.com/60937304/137291442-d8525b50-40c7-42a7-a1ac-8765be955214.png" />
              </Col>
              <Col xs={12} md={6}>
                <div>
                  <p style={{fontSize: '5rem'}}>Practice</p>
                  <p>
                    <ul>
                      <li>Over 1000+ questions of Data Structures and Algorithms</li>
                      <li>From LeetCode, GeeksForGeeks, CodeChef, CodeForces, SPOJ, AtCoder and more!</li>
                      <li>Curated by industry experts - and trusted by hundreds of thousands.</li>
                    </ul>
                  </p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <div>
                  <p style={{fontSize: '5rem'}}>Practice</p>
                  <p>
                    <ul>
                      <li>Over 1000+ questions of Data Structures and Algorithms</li>
                      <li>From LeetCode, GeeksForGeeks, CodeChef, CodeForces, SPOJ, AtCoder and more!</li>
                      <li>Curated by industry experts - and trusted by hundreds of thousands.</li>
                    </ul>
                  </p>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <img className={styles.landingImg} src="https://user-images.githubusercontent.com/60937304/137291442-d8525b50-40c7-42a7-a1ac-8765be955214.png" />
              </Col>
            </Row>
          </Container>
        </ParallaxLayer>

        {/* <ParallaxLayer
          offset={5}
          speed={0.5}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

        </ParallaxLayer> */}
        </Parallax>
    </div>
  )
}



